/* eslint-disable unicorn/prefer-top-level-await */
import { z } from 'zod';

import { createFileRoute } from '@tanstack/react-router';

const searchValidators = z.object({
	searchCs: z.string().optional().catch(''),
	addCsContact: z.boolean().optional().catch(false),
	addLogisticianContact: z.boolean().optional().catch(false),
	noArchived: z.boolean().optional().catch(true),
	unarchive: z.string().optional().catch(''),
	addCs: z.boolean().optional().catch(false),

	//Instant payment handling
	order_number: z.string().optional().catch(''),
});

export const Route = createFileRoute('/_dashboard/dashboard/orders/new/')({
	validateSearch: (search: Record<string, unknown>): z.infer<typeof searchValidators> => {
		return searchValidators.parse(search);
	},
});
