import React from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { useRouterState } from '@tanstack/react-router';

import { Footer } from '@/components/footer';
import { Header } from './features/header';

type DashboardLayoutProperties = {
	displayLogo?: boolean;
	displayMenu?: boolean;
	displayOrderButton?: boolean;
	displayClientName?: boolean;
	displayDrpdown?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const DashboardLayout: React.FC<DashboardLayoutProperties> = ({
	children,
	displayLogo = true,
	displayMenu = true,
	displayOrderButton = true,
	displayClientName = true,
	displayDrpdown = true,
}) => {
	const isDesktop = useMediaQuery('(min-width: 768px)');

	const { location } = useRouterState();
	return (
		<div className="min-h-screen flex flex-col items-stretch">
			<Header
				displayLogo={displayLogo}
				displayMenu={displayMenu}
				displayOrderButton={location.pathname !== '/dashboard/orders/new/' && displayOrderButton}
				displayClientName={isDesktop && displayClientName}
				displayDrpdown={displayDrpdown}
			/>
			<div className="flex items-start justify-center flex-1">
				<div className="w-full min-h-full flex items-center justify-between p-4 max-w-screen-2xl">{children}</div>
			</div>
			<Footer />
		</div>
	);
};
