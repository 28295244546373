import { useQuery } from '@tanstack/react-query';

import { useSignoutOptions } from './signout.options';

export const useSignout = (enabled = true) => {
	const { refetch, isPending, isSuccess, isError } = useQuery(useSignoutOptions(enabled));

	return {
		signout: refetch,
		signoutPending: isPending,
		signoutSuccess: isSuccess,
		signoutError: isError,
	};
};
