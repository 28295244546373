/* eslint-disable unicorn/prefer-top-level-await */
import { z } from 'zod';

import { getCurrentMonthDate } from '@/utils/dates';
import { createFileRoute } from '@tanstack/react-router';

const { from, to } = getCurrentMonthDate();

const searchValidators = z
	.object({
		search: z.string().optional().catch(''),
		from: z.string().datetime().optional().default(from).catch(from),
		to: z.string().datetime().optional().default(to).catch(to),
		pageSize: z.number().int().optional().default(25),
		pageNumber: z.number().int().optional().default(1),
		activeTab: z.enum(['future', 'history']).default('future').catch('future'),
		cancel: z.string().optional().catch(''),
	})
	.optional()
	.default({ from, to, pageSize: 25, pageNumber: 1, activeTab: 'future' })
	.catch({ from, to, pageSize: 25, pageNumber: 1, activeTab: 'future' });

export const Route = createFileRoute('/_dashboard/dashboard/collects/')({
	validateSearch: (search: Record<string, unknown>): z.infer<typeof searchValidators> => {
		return searchValidators.parse(search);
	},
});
