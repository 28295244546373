import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { AlertTriangle } from 'lucide-react';
import React from 'react';

import { cn } from '@/lib/utils';
import { InfoCircledIcon } from '@radix-ui/react-icons';

type InfoOrWarningProperties = React.PropsWithChildren<{
	type?: 'info' | 'warning';
	text?: string;
}> &
	VariantProps<typeof infoOrWarningVariants> &
	React.HTMLAttributes<HTMLDivElement>;

const infoOrWarningVariants = cva('w-full rounded-sm p-2', {
	variants: {
		type: {
			info: 'bg-blue-300/20',
			warning: 'bg-orange-300/20',
		},
	},
	defaultVariants: {
		type: 'info',
	},
});

/**
 * Display a card with additional data
 * @param type - The type of the card (info or warning) - default: info
 * @param text - The text to display (if children is not provided)
 * @param children - The children to display (if text is not provided)
 **/
export const InfoOrWarningCard: React.FC<InfoOrWarningProperties> = ({
	type = 'info',
	text,
	children,
	className,
	...properties
}) => {
	const displayTextOrChildren = () => {
		if (text) return <p className="text-sm">{text}</p>;
		return children;
	};

	return (
		<div className={cn(infoOrWarningVariants({ type, className }))} {...properties}>
			<div className={cn('flex items-center justify-start', type === 'info' ? 'text-blue-400' : 'text-orange-400')}>
				<div className="w-6 mr-3">
					{type === 'info' && <InfoCircledIcon className="inline-block size-6 mr-2" />}
					{type === 'warning' && <AlertTriangle className="inline-block size-6 mr-2" />}
				</div>
				{displayTextOrChildren()}
			</div>
		</div>
	);
};
