/* eslint-disable react-hooks/rules-of-hooks */
import { useVerifySessionOptions } from '@/hooks/requests/auth/sessions.options';
import { useMeOptions } from '@/hooks/requests/user/options';
import { DashboardLayout } from '@/layout/dashboard';
import { ECustomerCategory } from '@/types/global';
import { InternalStore } from '@/utils/locastorage';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_dashboard')({
	component: () => (
		<DashboardLayout>
			<Outlet />
		</DashboardLayout>
	),
	loader: async ({ context, navigate }) => {
		const queryClient = context.queryClient;

		const token = InternalStore.getItem('accessToken');

		if (!token) {
			return navigate({ to: '/login', replace: true });
		}

		try {
			await queryClient.fetchQuery(useVerifySessionOptions());

			const me = await queryClient.fetchQuery({
				// eslint-disable-next-line react-hooks/rules-of-hooks
				...useMeOptions(),
			});

			if (!me) {
				return navigate({ to: '/login', replace: true });
			}

			if (me.customer_id.length === 0) {
				return navigate({
					to: '/account/customer/create',
					search: {
						type: ECustomerCategory.PRO,
					},
					replace: true,
				});
			}
		} catch {
			return navigate({ to: '/login', replace: true });
		}
	},
});
