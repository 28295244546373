/* eslint-disable react-hooks/rules-of-hooks */
import { useVerifySessionOptions } from '@/hooks/requests/auth/sessions.options';
import { InternalStore } from '@/utils/locastorage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/login/')({
	loader: async ({ navigate, context }) => {
		const queryClient = context.queryClient;

		const token = InternalStore.getItem('accessToken');

		if (token) {
			await queryClient.fetchQuery(useVerifySessionOptions());
			return await navigate({ to: '/dashboard', replace: true });
		}
	},
});
