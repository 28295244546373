/* eslint-disable unicorn/prefer-top-level-await */
import { z } from 'zod';

import { createFileRoute } from '@tanstack/react-router';

const searchValidators = z
	.object({
		search: z.string().optional().catch(''),
		pageSize: z.number().int().optional().default(25),
		pageNumber: z.number().int().optional().default(1),
		create: z.boolean().default(false).catch(false),
		edit: z.string().optional().catch(''),
	})
	.optional()
	.default({ pageSize: 25, pageNumber: 1, create: false })
	.catch({ pageSize: 25, pageNumber: 1, create: false });

export const Route = createFileRoute('/_dashboard/dashboard/teams/')({
	validateSearch: (search: Record<string, unknown>): z.infer<typeof searchValidators> => {
		return searchValidators.parse(search);
	},
});
