import { AxiosError } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { MutationCache, QueryCache, QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { createRouter, Navigate, RouterProvider } from '@tanstack/react-router';

import { NewVersionModal } from './components/new-version-modal';
import { Toaster } from './components/ui/sonner';
import { WatermarkWrapper } from './components/watermark-wrapper';
import { AuthProvider, useAuth } from './context/auth';
import { useCheckMaintenanceModeOptions, useCheckVersionningOptions } from './hooks/requests/maintenance/options';
import { NotFoundPage } from './pages/404';
import { ErrorPage } from './pages/error';
import { LoaderPage } from './pages/loader';
import { MaintenancePage } from './pages/maintenance';
import { displayErrorToast } from './utils/toast';
import { environmentVar as environmentVariable } from './env';
import { routeTree } from './route-tree.generated';
import { initializeSentry } from './sentry';

import './index.css';

initializeSentry();

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}

declare module '@tanstack/react-query' {
	interface Register {
		defaultError: AxiosError;
	}
}

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (error) => {
			if (error.response?.status !== 401 && window.location.pathname !== '/login') {
				displayErrorToast(error);
			}

			return error;
		},
	}),
	mutationCache: new MutationCache({
		onError: (error) => {
			displayErrorToast(error);
		},
	}),
});

const router = createRouter({
	context: { queryClient, auth: undefined! },
	routeTree,
	defaultPreload: false,
	defaultPreloadStaleTime: 0,
	defaultPendingMinMs: 1500,
	defaultPendingMs: 500,
	defaultNotFoundComponent: () => <NotFoundPage />,
	defaultPendingComponent: () => <LoaderPage />,
	defaultErrorComponent: ({ error }) => {
		if (error instanceof AxiosError && error.response?.status === 401) {
			//Get current pathname
			const currentPath = window.location.pathname;
			return <Navigate to="/login" search={{ redirect: currentPath }} />;
		}

		return <ErrorPage error={error} />;
	},
});

const RouterProviderWithContext = () => {
	const auth = useAuth();
	const { data: lastVersionData } = useQuery(useCheckVersionningOptions());

	const [modalOpen, setModalOpen] = React.useState(false);

	React.useEffect(() => {
		if (lastVersionData && lastVersionData !== environmentVariable.VITE_APP_VERSION) {
			setModalOpen(true);
		}
	}, [lastVersionData]);

	if (auth.hydrated === false) {
		return null;
	}

	return (
		<>
			<NewVersionModal open={modalOpen} onOpenChange={setModalOpen} lastVersion={lastVersionData ?? ''} />
			<RouterProvider router={router} context={{ auth }} />
		</>
	);
};

const App = () => {
	const { data, isError } = useQuery(useCheckMaintenanceModeOptions());

	if (data === true || isError) {
		return <MaintenancePage />;
	}

	return (
		<AuthProvider>
			<RouterProviderWithContext />
		</AuthProvider>
	);
};

ReactDOM.createRoot(document.querySelector('#root')!).render(
	<React.StrictMode>
		<WatermarkWrapper
			content={environmentVariable.VITE_WATERMARK_CONTENT}
			display={environmentVariable.VITE_WATERMARK_DISPLAY}
		>
			<QueryClientProvider client={queryClient}>
				<App />
				<Toaster richColors closeButton />
			</QueryClientProvider>
		</WatermarkWrapper>
	</React.StrictMode>,
);
