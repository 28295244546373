/* eslint-disable unicorn/prefer-top-level-await */
/* eslint-disable unicorn/prevent-abbreviations */
import { z } from 'zod';

import { DEFAULT_API_TIMEOUT_IN_MS } from '@/constants';
import { createEnv } from '@t3-oss/env-core';

export const environmentVar = createEnv({
	/**
	 * The prefix that client-side variables must have. This is enforced both at
	 * a type-level and at runtime.
	 */
	clientPrefix: 'VITE_',

	client: {
		VITE_API_URL: z.string().url(),
		VITE_API_TIMEOUT: z.number().int().positive().default(DEFAULT_API_TIMEOUT_IN_MS),
		VITE_ENDLESS_PHONE_NUMBER: z.string().default('+33176341476'),
		VITE_ENDLESS_CONTACT_EMAIL: z.string().email().default('contact@endless.fr'),
		VITE_GOOGLE_API_KEY: z.string(),
		VITE_ADMIN_ID: z.string(),

		VITE_WATERMARK_DISPLAY: z.coerce.boolean().default(false).catch(false),
		VITE_WATERMARK_CONTENT: z.string().default('').catch(''),

		VITE_INTERNAL_API_URL: z.string().url(),
		VITE_SENTRY_DSN: z.string().url(),
		VITE_SENTRY_ENVIRONMENT: z.string(),
		VITE_SENTRY_DEBUG: z.coerce.boolean().default(false).catch(false),
		VITE_APP_VERSION: z.string(),
		VITE_SENTRY_SUCCESS_RECORD_RATE: z.string().default('0.1'),
		VITE_SENTRY_ERROR_RECORD_RATE: z.string().default('1'),
	},

	/**
	 * What object holds the environment variables at runtime. This is usually
	 * `process.env` or `import.meta.env`.
	 */
	runtimeEnv: import.meta.env,

	/**
	 * By default, this library will feed the environment variables directly to
	 * the Zod validator.
	 *
	 * This means that if you have an empty string for a value that is supposed
	 * to be a number (e.g. `PORT=` in a ".env" file), Zod will incorrectly flag
	 * it as a type mismatch violation. Additionally, if you have an empty string
	 * for a value that is supposed to be a string with a default value (e.g.
	 * `DOMAIN=` in an ".env" file), the default value will never be applied.
	 *
	 * In order to solve these issues, we recommend that all new projects
	 * explicitly specify this option as true.
	 */
	emptyStringAsUndefined: true,
	server: {},
});
