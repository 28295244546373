import { Loader } from 'lucide-react';

import LOGIN_LOGO from '@/assets/endless_logo_black.png';

export const LoaderPage = () => {
	return (
		//Center in middle of the screen with absolute positioning
		<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
			<div className="w-full h-full flex items-center justify-center flex-col gap-9">
				<img src={LOGIN_LOGO} alt="Endless Logo" className="w-72" loading="lazy" />
				<Loader size={70} className="animate-spin" />
			</div>
		</div>
	);
};
