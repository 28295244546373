import type { ECollectorRole } from '../collector';
import type { RelationsDto } from '../dto';
import type { IKafkaQuery } from '../kafka';
import type { CREATED_FROM, ISODate } from '../misc';

export type UserCreateDto = {
	email: string;
	firstname?: string;
	lastname?: string;
	roles: EUserRole[];
	phone_number?: string;
	profile_not_completed?: boolean;
	customer_id: string[];
	notify_user?: boolean;
	created_from?: CREATED_FROM;
};

export type UserCreateActionDto = UserCreateDto & {
	id?: string;
	invitation?: UserInvitation;
};

export interface UserEditDto extends RelationsDto<UserRo> {
	email?: string;
	firstname?: string;
	lastname?: string;
	roles?: EUserRole[];
	phone_number?: string;
	profile_not_completed?: boolean;
	notify_user?: boolean;
}

export interface UserEditActionDto extends UserEditDto {
	id_stripe_customer?: string;
	id_stripe_customer_1?: string;
	id_stripe_customer_2?: string;
	archived?: boolean;
	customer_id?: string[];
	invitation?: UserInvitation;
	update_authorization?: boolean;
}

export interface UserRo {
	id: string;

	firstname: string;
	lastname: string;
	fullname: string;
	owner: boolean;

	phone_number: string;

	email: string;
	roles: EUserRole[];
	profile_not_completed: boolean;

	customer_id: string[];
	id_stripe_customer: string | null;
	id_stripe_customer_1: string | null;
	id_stripe_customer_2: string | null;

	invitation: UserInvitation | null;

	archived: boolean;
	created_by: string;
	created_from: CREATED_FROM;
	created_at: ISODate;
	updated_at: ISODate;
}

export enum EUserRole {
	BBNG_USER_ADMIN = 'BBNG_USER_ADMIN',
	BBNG_USER_MANAGER = 'BBNG_USER_MANAGER',
	BBNG_USER_ACCOUNTANT = 'BBNG_USER_ACCOUNTANT',
	BBNG_USER_EMPLOYEE = 'BBNG_USER_EMPLOYEE',
	BBNG_USER_CUSTOM = 'BBNG_USER_CUSTOM',
}

export interface UserEditFromZohoEmailDto {
	email: string;
	firstname?: string;
	lastname?: string;
	phone_number?: any;
}

export interface UserQuery extends IKafkaQuery {
	customer_id?: string;
	has_invitation?: boolean;
	has_active_invitation?: boolean;
	invitation_customer_id?: string;
	role?: EUserRole;
}

export interface RolesRo {
	id: string;
	new_roles: EUserRole[] | ECollectorRole[];
	previous_roles: EUserRole[] | ECollectorRole[];
}

export type UserOtpVerifyDto = {
	email: string;
	code: string;
};

export type UserOtpVerifyAndCreateDto = {
	email: string;
	code: string;
	firstname: string;
	lastname: string;
	phone_number?: string;
};

export type UserInvitation = {
	customer_id: string;
	customer_name: string;
	customer_siret: string;
	expires_at: ISODate;
	token: string;
};
