/* eslint-disable @typescript-eslint/require-await */
import axios from 'axios';
import { DateTime } from 'luxon';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { environmentVar } from '@/env';
import { ndlssEndpoints } from '@/sdk/endpoints';
import { type AccessRo, type CollectorRo, CREATED_FROM, type IBbngResponse, type LoginDto } from '@/types/global';
import { InternalStore } from '@/utils/locastorage';

type AuthContextStates = {
	hydrated: boolean;
	isAuthenticated: boolean;
};

type AuthContextMethods = {
	startRegisterSession: (properties: { email: string }) => Promise<{ success: boolean; message?: string }>;
	startOtpSession: (properties: { email: string }) => Promise<{ success: boolean; message?: string }>;
	completeOtpSession: (properties: LoginDto) => Promise<{ success: boolean; message?: string }>;
	disconnect: () => Promise<void>;
};

export type AuthContextType = AuthContextStates & AuthContextMethods;

const defaultAuthContext: AuthContextType = {
	hydrated: false,
	isAuthenticated: false,
	startRegisterSession: async () => {
		throw new Error('AuthContext not initialized');
	},
	startOtpSession: async () => {
		throw new Error('AuthContext not initialized');
	},
	completeOtpSession: async () => {
		throw new Error('AuthContext not initialized');
	},
	disconnect: async () => {
		throw new Error('AuthContext not initialized');
	},
};

const AuthContext = React.createContext<AuthContextType>(defaultAuthContext);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [states, setStates] = React.useState<AuthContextStates>(defaultAuthContext);

	React.useEffect(() => {
		const accessToken = InternalStore.getItem('accessToken');
		const expireAt = InternalStore.getItem('expireAt');

		if (accessToken && expireAt) {
			const expireAtDate = DateTime.fromISO(expireAt);
			const now = DateTime.now();

			setStates((previous) => ({
				...previous,
				isAuthenticated: expireAtDate > now,
				hydrated: true,
			}));
		} else {
			setStates((previous) => ({
				...previous,
				hydrated: true,
			}));
		}
	}, []);

	const startRegisterSession = React.useCallback<AuthContextMethods['startRegisterSession']>(async (properties) => {
		const response = await axios.post<IBbngResponse>(
			ndlssEndpoints.auth.startRegisterSession,
			{ email: properties.email },
			{ baseURL: environmentVar.VITE_API_URL },
		);

		return {
			success: response.status === 200,
			message: response.data?.data?.message,
		};
	}, []);

	const startOtpSession = React.useCallback<AuthContextMethods['startOtpSession']>(async (properties) => {
		const response = await axios.post<IBbngResponse>(
			ndlssEndpoints.auth.startOtpSession,
			{ email: properties.email },
			{ baseURL: environmentVar.VITE_API_URL },
		);

		return {
			success: response.status === 200,
			message: response.data?.data?.message,
		};
	}, []);

	const completeOtpSession = React.useCallback<AuthContextMethods['completeOtpSession']>(async (properties) => {
		const response = await axios.post<IBbngResponse<AccessRo<CollectorRo>>>(
			ndlssEndpoints.auth.validateOtpSession,
			{
				email: properties.email,
				code: properties.code,
				firstname: properties.firstname,
				lastname: properties.lastname,
				phone_number: properties.phone_number,
				created_from: CREATED_FROM.ECOMMERCE
			} satisfies LoginDto,
			{ baseURL: environmentVar.VITE_API_URL },
		);

		if (response.status === 200 && response.data && response.data.data?.ro) {
			const data = response.data.data.ro;

			InternalStore.setItem('accessToken', data.access_token);
			InternalStore.setItem('expireAt', DateTime.now().plus({ seconds: data.expires_in }).toISO());

			setStates((previous) => ({
				...previous,
				isAuthenticated: true,
			}));
		}

		return {
			success: response.status === 200,
			message: response.data?.data?.message,
		};
	}, []);

	React.useEffect(() => {
		if (states.isAuthenticated === false && InternalStore.getItem('accessToken') && states.hydrated) {
			InternalStore.removeItem('accessToken');
			InternalStore.removeItem('expireAt');

			window.location.reload();
		}
	}, [states.isAuthenticated]);

	const disconnect = React.useCallback<AuthContextMethods['disconnect']>(async () => {
		setStates((previous) => ({
			...previous,
			isAuthenticated: false,
		}));
	}, []);

	const value = React.useMemo<AuthContextType>(
		() => ({
			...states,
			startOtpSession,
			completeOtpSession,
			startRegisterSession,
			disconnect,
		}),
		[states, startOtpSession, completeOtpSession, disconnect, startRegisterSession],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
