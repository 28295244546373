import React from 'react';

import { useClientName } from '../hooks/client-name';

export const ClientName: React.FC = () => {
	const { data } = useClientName();

	return (
		<div>
			<p className="italic font-black">{data.customer.ro?.name.toUpperCase()}</p>
		</div>
	);
};
