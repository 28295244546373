// import { AxiosError } from 'axios';
import { AxiosError } from 'axios';
import type { ExternalToast } from 'sonner';
import { toast } from 'sonner';

import { NdlssError } from '@/errors';
import type { IBbngResponse } from '@/types/global';

export const displayErrorToast = (error: unknown) => {
	if (error instanceof AxiosError) {
		const castedError = error as AxiosError<IBbngResponse>;
		const message = castedError.response?.data?.data?.message;

		const humanizedMessage = NdlssError.humanize(message ?? '');
		const title = `Une erreur est survenue - ${castedError.response?.status}`;
		const externalToast: ExternalToast = {
			description: humanizedMessage.message === 'Erreur inconnue' ? message : humanizedMessage.message,
		};
		return toast.error(title, externalToast);
	}
	if (typeof error === 'string') {
		return toast.error('Une erreur est survenue', {
			description: error,
		});
	}
};

export const displaySuccessToast = (message: string) => {
	const externalToast: ExternalToast = {
		description: message,
		duration: 5000,
	};

	return toast.success('Succès', externalToast);
};
