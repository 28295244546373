/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable unicorn/prefer-top-level-await */
import { z } from 'zod';

import { useMeOptions } from '@/hooks/requests/user/options';
import type { UserRo } from '@/types/global';
import { ECustomerCategory } from '@/types/global';
import { createFileRoute } from '@tanstack/react-router';

const searchValidators = z.object({
	type: z
		.enum([ECustomerCategory.INDIVIDUAL, ECustomerCategory.PRO])
		.default(ECustomerCategory.PRO)
		.catch(ECustomerCategory.PRO),
});

export const Route = createFileRoute('/account/customer/create/')({
	validateSearch: (search: Record<string, unknown>): z.infer<typeof searchValidators> => {
		return searchValidators.parse(search);
	},
	loader: async ({ context, navigate }) => {
		//Redirect to dashboard if the user has a customer.
		const queryClient = context.queryClient;

		let me: UserRo | undefined;
		try {
			me = await queryClient.fetchQuery(useMeOptions());
		} catch (error) {
			console.error(error);
		}

		if (!me) {
			await navigate({
				to: '/login',
				replace: true,
			});
			return;
		}

		if (me.customer_id && me.customer_id.length > 0) {
			await navigate({
				to: '/dashboard',
				replace: true,
			});
			return;
		}
	},
});
