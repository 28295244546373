import { Mail, PhoneCall } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import { useLocalStorage, useMediaQuery } from 'usehooks-ts';

import { Button } from '@/components/ui/button';
import { GOD_MODE_KEY } from '@/constants';
import { environmentVar } from '@/env';
import { cn } from '@/lib/utils';

export const Footer: React.FC = () => {
	const year = DateTime.now().year;
	const [isGodMode, _] = useLocalStorage(GOD_MODE_KEY, false);
	const isDesktop = useMediaQuery('(min-width: 800px)');

	return (
		<footer className="bottom-0 z-50 w-full border-t bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 flex items-center justify-center">
			<div className={cn(
				"w-full flex flex-wrap gap-2 items-center justify-between p-4 max-w-screen-2xl",
				!isDesktop && 'justify-center'
				)}>
				<div className={cn(
					"flex items-center justify-center gap-3",
					!isDesktop && 'w-full'
				)}>
					<span className="text-sm text-slate-400">© {year} Endless</span>
					<span className="text-sm text-slate-400">v{environmentVar.VITE_APP_VERSION}</span>
				</div>
				<div className={cn(
					"flex items-center justify-center",
					!isDesktop && 'w-full'
				)}>
					<Button
						className="text-slate-400"
						variant="link"
						onClick={() => window.open('https://endless.fr/confidentialite/', '_blank')}
					>
						Confidentialité
					</Button>
					<span className="text-sm text-slate-400">|</span>
					<Button
						className="text-slate-400"
						variant="link"
						onClick={() => window.open('https://www.endless.fr/cgv-web/', '_blank')}
					>
						CGV
					</Button>
				</div>
				<div className={cn(
					"flex items-center justify-center gap-3",
					!isDesktop && 'w-full'
				)}>
					<Button variant="outline">
						<a
							className="flex items-center justify-center gap-3"
							href={`tel:${environmentVar.VITE_ENDLESS_PHONE_NUMBER}`}
						>
							<PhoneCall size={12} />
							{isDesktop && <span>Appelez-nous</span>}
						</a>
					</Button>

					<span className="text-sm text-slate-400">|</span>

					<Button variant="outline">
						<a
							className="flex items-center justify-center gap-3"
							href={`mailto:${environmentVar.VITE_ENDLESS_CONTACT_EMAIL}`}
						>
							<Mail size={12} />
							{isDesktop && <span>Ecrivez-nous</span>}
						</a>
					</Button>
					{isGodMode && (
						<Button variant="destructive" onClick={() => {
							throw new Error('TOUT CASSER');
						}}>
							{isDesktop && <span>TOUT CASSER</span>}
						</Button>
					)}
				</div>
			</div>
		</footer>
	);
};
