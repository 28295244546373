import { ndlssEndpoints } from '@/sdk/endpoints';
import { httpClient } from '@/sdk/sdk';
import type { IBbngResponse } from '@/types/global';
import { queryOptions } from '@tanstack/react-query';

import { queryKeys } from '../keys';

export const useVerifySessionOptions = () => {
	return queryOptions({
		queryKey: [queryKeys.auth.verifySession],
		queryFn: async () => {
			await httpClient.post<IBbngResponse>(ndlssEndpoints.auth.verifyToken);
			return true;
		},
		retry: false,
	});
};
