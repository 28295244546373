import { Button } from '@/components/ui/button';
import { useNavigate } from '@tanstack/react-router';

export const NotFoundPage = () => {
	const navigate = useNavigate({ from: '/404' });

	return (
		<div className="flex flex-col items-center justify-center h-screen w-full">
			<h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">404</h1>
			<p className="mt-4 text-center text-gray-600 dark:text-gray-400">
				Il semble que vous soyez perdu... Malheureusement, la page que vous recherchez n'existe pas. <br />
				Vous pouvez retourner à la page d'accueil en cliquant sur le bouton ci-dessous.
			</p>
			<Button
				variant={'default'}
				className="mt-4"
				onClick={() => {
					navigate({ to: '/login', replace: true }).catch(console.error);
				}}
			>
				Retour à l'accueil
			</Button>
		</div>
	);
};
