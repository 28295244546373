import axios from 'axios';

import { environmentVar } from '@/env';
import { Sentry } from '@/sentry';
import { InternalStore } from '@/utils/locastorage';

export const httpClient = axios.create({
	baseURL: environmentVar.VITE_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

httpClient.interceptors.request.use((config) => {
	const token = InternalStore.getItem('accessToken');

	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

// Sentry error handling
httpClient.interceptors.response.use(undefined, (error) => {
	if (axios.isAxiosError(error)) {
		Sentry.captureException(error);
	}

	return Promise.reject(error);
});

httpClient.interceptors.request.use((config) => {
	config.params ??= {};
	if (config.params.sync === undefined) {
		config.params.sync = true;
	}

	return config;
});
