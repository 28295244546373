/* eslint-disable unicorn/prefer-top-level-await */
import { z } from 'zod';

import { getCurrentMonthDate } from '@/utils/dates';
import { createFileRoute } from '@tanstack/react-router';

const { from, to } = getCurrentMonthDate();

const searchValidators = z
	.object({
		from: z.string().datetime().optional().default(from).catch(from),
		to: z.string().datetime().optional().default(to).catch(to),
		pageSize: z.number().int().optional().default(25),
		pageNumber: z.number().int().optional().default(1),
	})
	.optional()
	.default({ from, to, pageSize: 25, pageNumber: 1 })
	.catch({ from, to, pageSize: 25, pageNumber: 1 });

export const Route = createFileRoute('/_dashboard/dashboard/invoices/')({
	validateSearch: (search: Record<string, unknown>): z.infer<typeof searchValidators> => {
		return searchValidators.parse(search);
	},
});
