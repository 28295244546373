import { ndlssEndpoints } from '@/sdk/endpoints';
import { httpClient } from '@/sdk/sdk';
import { queryOptions } from '@tanstack/react-query';

import { queryKeys } from '../keys';

export const useSignoutOptions = (enabled = true) => {
	return queryOptions({
		queryKey: [queryKeys.auth.signout],
		queryFn: async () => {
			await httpClient.get(ndlssEndpoints.auth.signout);
			return true;
		},
		enabled,
	});
};
