export const StorageKeys = {
	accessToken: 'accessToken',
	expireAt: 'expireAt',
} as const;

export type StorageKeys = (typeof StorageKeys)[keyof typeof StorageKeys];

export type StorageKeysType = {
	[StorageKeys.accessToken]: string;
	[StorageKeys.expireAt]: string;
};

export const InternalStore = {
	// eslint-disable-next-line @typescript-eslint/ban-types
	getItem: <T extends StorageKeys | (string & {})>(
		key: T,
	): (T extends StorageKeys ? StorageKeysType[T] : string) | null => {
		return localStorage.getItem(key) as (T extends StorageKeys ? StorageKeysType[T] : string) | null;
	},

	// eslint-disable-next-line @typescript-eslint/ban-types
	setItem: <T extends StorageKeys | (string & {})>(
		key: T,
		value: T extends StorageKeys ? StorageKeysType[T] : string,
	) => {
		localStorage.setItem(key, value as string);
	},

	// eslint-disable-next-line @typescript-eslint/ban-types
	removeItem: <T extends StorageKeys | (string & {})>(key: T) => {
		localStorage.removeItem(key);
	},
};
