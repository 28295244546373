import { InfoOrWarningCard } from '@/components/info-or-warning';
import { Button } from '@/components/ui/button';
import { InternalStore } from '@/utils/locastorage';

export const ErrorPage = ({ error }: { error: unknown }) => {
	return (
		<div className="flex flex-col items-center justify-center h-screen w-full">
			<h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">Oops...</h1>
			<p className="mt-4 text-center text-gray-600 dark:text-gray-400">
				Une erreur est survenue lors de la navigation inter page. <br />
				Ce n'est pas censé arriver. <br />
				<div className="mb-3" />
				<strong className="underline">
					Nous vous conseillons de recharger votre page. <br />
				</strong>
				<div className="mb-9" />
				Mais si le problème persiste, veuillez cliquez sur le bouton ci-dessous.
			</p>
			<Button
				variant={'default'}
				className="mt-4"
				onClick={() => {
					InternalStore.removeItem('accessToken');
					InternalStore.removeItem('expireAt');
					window.location.reload();
				}}
			>
				Revenir à la page de connexion
			</Button>

			<div className="mb-9" />

			<InfoOrWarningCard type="warning" text={error?.toString()} className="max-w-fit" />
		</div>
	);
};
