/* eslint-disable unicorn/prefer-top-level-await */
import { z } from 'zod';

import { createFileRoute } from '@tanstack/react-router';

const searchValidators = z
	.object({
		activeTab: z.enum(['general', 'contacts', 'billing']).default('general').catch('general'),
		contactEdit: z.number().optional(),
	})
	.optional()
	.default({ activeTab: 'general' })
	.catch({ activeTab: 'general' });

export const Route = createFileRoute('/_dashboard/dashboard/informations/')({
	validateSearch: (search: Record<string, unknown>): z.infer<typeof searchValidators> => {
		return searchValidators.parse(search);
	},
});
