import { getCurrentMonthDate } from '@/utils/dates';
import type { ToSubOptions } from '@tanstack/react-router';

const { from, to } = getCurrentMonthDate();

export const navbarData: {
	label: string;
	to: ToSubOptions['to'];
	search: ToSubOptions['search'];
}[] = [
	{
		label: 'Mes commandes',
		to: '/dashboard/collects',
		search: {
			from,
			to,
			pageNumber: 1,
			pageSize: 25,
			activeTab: 'future',
		},
	},
	{
		label: 'Mes chantiers',
		to: '/dashboard/contruction-sites',
		search: {
			pageNumber: 1,
			pageSize: 25,
			create: false,
		},
	},
	{
		label: 'Mes factures',
		to: '/dashboard/invoices',
		search: {
			from,
			to,
			pageNumber: 1,
			pageSize: 25,
		},
	},
	{
		label: 'Ma data',
		to: '/dashboard/data',
		search: {
			from,
			to,
			pageNumber: 1,
			pageSize: 25,
		},
	},
	{
		label: 'Mes rapports',
		to: '/dashboard/reports',
		search: {},
	},
];

export const navbarDataMobile: {
	label: string;
	to: ToSubOptions['to'];
	search: ToSubOptions['search'];
}[] = [
	{
		label: 'Mes commandes',
		to: '/dashboard/collects',
		search: {
			from,
			to,
			pageNumber: 1,
			pageSize: 25,
			activeTab: 'future',
		},
	},
	{
		label: 'Mes chantiers',
		to: '/dashboard/contruction-sites',
		search: {
			pageNumber: 1,
			pageSize: 25,
			create: false,
		},
	},
	{
		label: 'Mon équipe',
		to: '/dashboard/teams',
		search: {
			pageNumber: 1,
			pageSize: 25,
			create: false,
		},
	},
	{
		label: 'Mes informations',
		to: '/dashboard/informations',
		search: {
			activeTab: 'general',
		},
	},
	{
		label: 'Mes factures',
		to: '/dashboard/invoices',
		search: {
			from,
			to,
			pageNumber: 1,
			pageSize: 25,
		},
	},
	{
		label: 'Ma data',
		to: '/dashboard/data',
		search: {
			from,
			to,
			pageNumber: 1,
			pageSize: 25,
		},
	},
	{
		label: 'Mes rapports',
		to: '/dashboard/reports',
		search: {},
	},
];
