export const ndlssEndpoints = {
	auth: {
		startRegisterSession: '/v1/users-auth/auth/otp/signup',
		startOtpSession: '/v1/users-auth/auth/otp/login',
		validateOtpSession: '/v1/users-auth/auth/otp-verify',
		verifyToken: '/v1/users-auth/auth/verify',
		signout: '/v1/users-auth/auth/signout',
	},
	users: {
		getMe: '/v1/users-auth/auth/me',
		getAll: '/v1/users',
		create: '/v1/users',
		getById: (id: string) => `/v1/users/${id}`,
		updateById: (id: string) => `/v1/users/${id}`,
	},
	collects: {
		getAll: '/v1/collects',
		dumpsterOnSite: '/v1/collects/dumpster-on-site/',
	},
	ccs: {
		retrieve: '/v1/collect-configs/retrieve',
		getAll: '/v1/collect-configs',
		getById: (id: string) => `/v1/collect-configs/${id}`,
		cancel: (id: string) => `/v1/collect-configs/${id}/cancel`,
	},
	constructionSites: {
		create: '/v1/construction-sites',
		retrieve: '/v1/construction-sites/retrieve',
		getAll: '/v1/construction-sites',
		getById: (id: string) => `/v1/construction-sites/${id}`,
		updateById: (id: string) => `/v1/construction-sites/${id}`,
		archiveById: (id: string) => `/v1/construction-sites/${id}/archive`,
	},
	documents: {
		getById: (id: string) => `/v1/documents/${id}`,
	},
	customers: {
		me: (id: string) => `/v1/customers/${id}`,
		kpiData: '/v1/customers/kpi/data/me',
		revalorisation: '/v1/customers/kpi/revalorisation/me',
		updateById: (id: string) => `/v1/customers/${id}`,
		create: '/v1/customers',
		verify: (siret: string) => `/v1/customers/verify/${siret}`,
		invitation: (siret: string) => `/v1/customers/${siret}/create-invitation`,
		revalorisationExport: '/v1/customers/revalorisation/export',
		removeUser: '/v1/customers/remove-user-from-customer'
	},
	zones: {
		getAll: '/v1/zones',
	},
	products: {
		getCancelationPrice: (ccId: string) => `/v1/products/cancelation-price/${ccId}`,
		getAll: '/v1/products/public-priced',
		retrieve: '/v1/products/retrieve',
	},
	discounts: {
		getAll: '/v1/discounts',
	},
	orders: {
		create: '/v1/orders',
		calculateCartPrice: '/v1/orders/calculate-cart-price',
		nextAvailableDay: (zoneId: string) => `/v1/orders/next-available-day/${zoneId}`,
		resendLink: (orderId: string) => `/v1/orders/${orderId}/resend-link`,
	},
	invoices: {
		getAll: '/v1/invoices',
		download: (id: string) => `/v1/invoices/download/${id}`,
	},
};
