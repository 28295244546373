import { useGetCustomerMeOptions } from '@/hooks/requests/customers/options';
import { useMeOptions } from '@/hooks/requests/user/options';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useClientName = () => {
	const { data: me } = useSuspenseQuery(useMeOptions());
	const { data: customer } = useSuspenseQuery(useGetCustomerMeOptions(!!me?.id, me?.customer_id?.[0] ?? ''));

	return {
		data: {
			me,
			customer,
		},
	};
};
