import { DateTime } from 'luxon';

import { type ESlot, MAX_START_TIME_ORDER_POST_IVORY,MIN_START_TIME_ORDER_POST_IVORY } from '@/types/global';

import { mapSlotTextToApi } from './mapper';

export const getCurrentMonthDate = () => {
	const fromDate = DateTime.now().toUTC().startOf('day').startOf('month').toISO();
	const toDate = DateTime.now().toUTC().endOf('day').toISO();

	return { from: fromDate, to: toDate };
};

export const getWeekendsDays = ({ from, to }: { from: string; to: string }) => {
	const weekends = [];
	const fromDate = DateTime.fromISO(from);
	const toDate = DateTime.fromISO(to);

	for (let day = fromDate; day <= toDate; day = day.plus({ days: 1 })) {
		if (day.weekday === 6 || day.weekday === 7) {
			weekends.push(day.toJSDate());
		}
	}

	return weekends;
};

export const getClosedDaysForZone = ({
	period,
	forbiddenWeekdays,
	forbiddenDays,
}: {
	period: { from: string; to: string },
	forbiddenWeekdays: number[],
	forbiddenDays: Record<string, true>,
}) => {
	const closedDays = [];
	const fromDate = DateTime.fromISO(period.from);
	const toDate = DateTime.fromISO(period.to);

	for (let day = fromDate; day <= toDate; day = day.plus({ days: 1 })) {
		if (forbiddenWeekdays.includes(day.weekday)) {
			closedDays.push(day.toJSDate());
		} else {
			const dayString = day.toFormat('yyyy-MM-dd');
			if (forbiddenDays[dayString]) {
				closedDays.push(day.toJSDate());
			}
		}
	}

	return closedDays;
};

export const getNextBusinessDay = (
	isValidProposedDay?: (foundNextBusinessDay: string) => boolean,
	currentDay?: DateTime<true>,
): Date => {
	const today = DateTime.now();
	const nextBusinessDay = (currentDay ?? today).plus({ days: 1 });

	if (nextBusinessDay.weekday === 6 || nextBusinessDay.weekday === 7) {
		return getNextBusinessDay(isValidProposedDay, nextBusinessDay);
	}

	if (isValidProposedDay && !isValidProposedDay(nextBusinessDay.toISO())) {
		return getNextBusinessDay(isValidProposedDay, nextBusinessDay);
	}

	return nextBusinessDay.toJSDate();
};

export const getAllDaysBeetween = ({ from, to }: { from: string; to: string }) => {
	const days = [];
	const fromDate = DateTime.fromISO(from);
	const toDate = DateTime.fromISO(to);

	for (let day = fromDate; day <= toDate; day = day.plus({ days: 1 })) {
		days.push(day.toJSDate());
	}

	return days;
};

export const removeOffsetTime = (date?: Date) => {
	const stringDate = date ? DateTime.fromJSDate(date).toISO() : DateTime.now().toISO();
	return `${stringDate?.split('+')[0]}Z`;
};

export const mergeDateWithTime = (date: string, time: string) => {
	const [hours, minutes] = time.split(':');
	return DateTime.fromISO(date)
		.set({ hour: Number(hours), minute: Number(minutes) })
		.toISO();
};

/**
 * Retrieve the start date from input slot
 * @param {ESlot} slot
 * @returns {Date}
 */
export const getStartDateFromSlot = (slot: ESlot): Date => {
	const [from] = mapSlotTextToApi(slot).split('-');

	if (!from) return DateTime.now().toJSDate();

	const now = DateTime.now().toISO();
	const startDate = mergeDateWithTime(now, from);

	return DateTime.fromISO(startDate ?? now).toJSDate();
}

/**
 * Check if the fromTime is valid based on business constraints and input slot.
 * @param {ESlot} slot
 * @param {Date} fromTime
 * @returns {boolean}
 */
export const checkFromTimeIsValid = (slot: ESlot, fromTime: Date): boolean => {
	const roundedFromTime = DateTime.fromJSDate(fromTime).set({ second: 0, millisecond: 0 }).toJSDate();
	const minStartTime = DateTime.now().setZone("Europe/Paris").set(MIN_START_TIME_ORDER_POST_IVORY).toJSDate();
	const maxStartTime = DateTime.now().setZone("Europe/Paris").set(MAX_START_TIME_ORDER_POST_IVORY).toJSDate();

	const slotStartTime = getStartDateFromSlot(slot);
	const roundedSlotStartTime = DateTime.fromJSDate(slotStartTime).set({ second: 0, millisecond: 0 }).toJSDate();

	if (roundedFromTime < roundedSlotStartTime) return false;
	if (roundedFromTime < minStartTime) return false;
	if (roundedFromTime > maxStartTime) return false;

	return true;
};
