import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const searchValidators = z.object({
	order_number: z.string().optional().catch(''),
});

export const Route = createFileRoute('/_dashboard/dashboard/orders/new/payment-canceled/')({
	validateSearch: (search: Record<string, unknown>): z.infer<typeof searchValidators> => {
		return searchValidators.parse(search);
	},
});
