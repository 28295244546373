import axios from 'axios';

import { environmentVar } from '@/env';
import { queryOptions } from '@tanstack/react-query';

import { queryKeys } from '../keys';

export const useCheckMaintenanceModeOptions = (enabled = true) => {
	return queryOptions({
		queryKey: [queryKeys.maintenance.check],
		queryFn: async () => {
			const response = await axios.get<{ isMaintenanceActivated: boolean }>(
				`${environmentVar.VITE_INTERNAL_API_URL}/maintenance`,
			);

			return response.data.isMaintenanceActivated;
		},
		enabled,
		refetchInterval: 1000 * 30, // 30 seconds
	});
};

export const useCheckVersionningOptions = (enabled = true) => {
	return queryOptions({
		queryKey: [queryKeys.versionning.check],
		queryFn: async () => {
			const response = await axios.get<{ lastVersion: string | null }>(
				`${environmentVar.VITE_INTERNAL_API_URL}/versionning/check`,
			);

			return response.data.lastVersion;
		},
		enabled,
		refetchInterval: 1000 * 30, // 30 seconds
	});
};
