import type { AddressCreateDto, AddressEditDto, AddressRo } from '../address';
import type { IKafkaQuery } from '../kafka';
import type { ICloseTime, IContact, IOpeningTime, ISODate, ITrashDetails } from '../misc';
import { ETrashType } from '../misc';
import type { EPlanningRegion, EPlanningType } from '../planning';

export type LandfillCreateDto = {
	test_name?: string;
	name: string;
	code_name?: string;
	siren: string;
	siret: string;
	accept_bigbag: boolean;
	accept_dumpster: boolean;
	phone_number: string;
	fax_number?: string;
	additional_info?: string;
	contact?: Array<IContact>;
	opening_time: IOpeningTime;
	maximum_daily_volume_m3?: number;
	exceptional_closure?: Array<ICloseTime>;
	address: AddressCreateDto;
	trash_details: Array<ITrashDetails>;
	average_waiting_minutes: number;
	inventory?: ILandfillInventory;
	owner_is_endless: boolean;
};
export type LandfillCreateActionDto = LandfillCreateDto & {
	id?: string;
};

export type LandfillEditDto = {
	test_name?: string;
	name?: string;
	code_name?: string;
	siren?: string;
	siret?: string;
	phone_number?: string;
	fax_number?: string;
	accept_bigbag?: boolean;
	accept_dumpster?: boolean;
	additional_info?: string;
	contact?: Array<IContact>;
	opening_time?: IOpeningTime;
	maximum_daily_volume_m3?: number;
	exceptional_closure?: Array<ICloseTime>;
	address?: AddressEditDto;
	trash_details?: Array<ITrashDetails>;
	average_waiting_minutes?: number;
	inventory?: ILandfillInventory;
	owner_is_endless?: boolean;
};

export type LandfillEditActionDto = LandfillEditDto & {
	archived?: boolean;
};

export type RecyclingCharacteristicCreateDto = {
	landfill_id: string;
	recycling_info: Record<ETrashType, RevalorisationCharacteritics>;
	from_date: ISODate;
	to_date?: ISODate;
};

export type RecyclingCharacteristicCreateActionDto = RecyclingCharacteristicCreateDto;

export type RecyclingCharacteristicEditDto = Partial<Omit<RecyclingCharacteristicCreateDto, 'landfill_id'>> & {
	landfill_id: string;
};

export type RecyclingCharacteristicEditActionDto = RecyclingCharacteristicEditDto;

export type RecyclingCharacteristicsRo = {
	id: string;
	landfill_id: string;
	recycling_info: Record<ETrashType, RevalorisationCharacteritics>;
	from_date: ISODate;
	to_date: ISODate | null;
};

export type RevalorisationCharacteritics = {
	reusage?: number;
	recycling?: number;
	energetic_valorisation?: number;
	elimination?: number;
	global_valorisation?: number;
};

export interface LandfillRo {
	id: string;
	test_name?: string;
	name: string;
	code_name: string;
	siren: string | null;
	siret: string | null;
	accept_bigbag: boolean;
	accept_dumpster: boolean;
	phone_number: string;
	fax_number: string | null;
	additional_info: string | null;
	maximum_daily_volume_m3: number | null;
	contact: Array<IContact>;
	opening_time: IOpeningTime;
	exceptional_closure: Array<ICloseTime>;
	address: AddressRo;
	trash_details: Array<ITrashDetails>;
	created_by: string;
	created_at: ISODate;
	updated_at: ISODate;
	average_waiting_minutes: number;
	region: EPlanningRegion;
	archived: boolean;
	inventory: ILandfillInventory | null;
	owner_is_endless: boolean;
}

export interface ILandfillInventory {
	nb_8m3_dumpster: number;
	nb_15m3_dumpster: number;
	nb_20m3_dumpster: number;
	nb_30m3_dumpster: number;
}

export enum ELandfillDangerousTrashType {
	LEAD = ETrashType.LEAD,
	ASBESTOS = ETrashType.ASBESTOS,
}

export interface LandfillQuery extends IKafkaQuery {
	region?: EPlanningRegion;
	collect_type?: EPlanningType;
	owner_is_endless?: boolean;
}
