export const MaintenancePage = () => {
	return (
		<div className="flex flex-col items-center justify-center h-screen w-full">
			<h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">Maintenance</h1>
			<p className="mt-4 text-left text-gray-600 dark:text-gray-400 w-4/5 text-wrap ">
				Cher visiteur,
				<br />
				<br />
				Nous sommes actuellement en train de peaufiner quelques détails pour améliorer votre expérience sur notre site.
				Nous nous excusons pour la gêne occasionnée. Soyez assuré que nous travaillons sans relâche pour revenir en
				ligne le plus rapidement possible.
				<br />
				<br />
				Votre patience et votre compréhension sont grandement appréciées pendant cette période de maintenance. Nous
				avons hâte de vous retrouver bientôt avec une plateforme encore plus performante et enrichissante.
				<br />
				<br />À très bientôt !
				<br />
				<br />
				<div className="flex items-center justify-end">L'équipe Endless.</div>
			</p>
		</div>
	);
};
