import { ndlssEndpoints } from '@/sdk/endpoints';
import { httpClient } from '@/sdk/sdk';
import type { IBbngResponse, UserQuery, UserRo } from '@/types/global';
import { queryOptions } from '@tanstack/react-query';

import { queryKeys } from '../keys';

export const useMeOptions = (enabled = true) => {
	return queryOptions({
		queryKey: [queryKeys.users.me],
		queryFn: async () => {
			const { data } = await httpClient.get<IBbngResponse<UserRo>>(ndlssEndpoints.users.getMe);

			return data.data.ro;
		},
		enabled,
		staleTime: 1000 * 60 * 5 /* 5 minutes */,
	});
};

export const useGetAllMembersOptions = (enabled = true, query: { search?: string } & UserQuery) => {
	return queryOptions({
		queryKey: [queryKeys.users.getAll, { ...query }],
		queryFn: async () => {
			const data = await httpClient.get<IBbngResponse<UserRo[]>>(ndlssEndpoints.users.getAll, {
				params: {
					...query,
					limit: query.limit,
					page: query.page,
					q: query.search,
				} satisfies UserQuery,
			});

			return data.data.data;
		},
		enabled,
	});
};

export const useGetMemberByIdOptions = (enabled = true, id: string) => {
	return queryOptions({
		queryKey: [queryKeys.users.getById, { id }],
		queryFn: async () => {
			const { data } = await httpClient.get<IBbngResponse<UserRo>>(ndlssEndpoints.users.getById(id));

			return data.data.ro;
		},
		enabled,
	});
};
