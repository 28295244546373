import React from 'react';

import { useSignout } from '@/hooks/requests/auth/signout.query';
import { useMeOptions } from '@/hooks/requests/user/options';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAuth } from '@/context/auth';

const getInitials = (firstName: string, lastName: string) => {
	const firstInitial = firstName.charAt(0).toUpperCase();
	const lastInitial = lastName.charAt(0).toUpperCase();

	return `${firstInitial}${lastInitial}`;
};

export const useHeader = () => {
	const { signout, signoutError, signoutPending, signoutSuccess } = useSignout(false);

	const [drpOpen, setDrpOpen] = React.useState(false);

	const { disconnect } = useAuth();

	const {
		data: me,
		isSuccess: meIsSuccess,
		isPending: meIsPending,
		isError: meIsError,
		refetch: refetchMe,
	} = useSuspenseQuery(useMeOptions());

	React.useEffect(() => {
		if (signoutSuccess) {
			disconnect();
		}
	}, [signoutSuccess]);

	return {
		drp: {
			value: drpOpen,
			onChange: setDrpOpen,
		},
		utils: {
			getInitials,
		},
		data: {
			me,
		},
		requests: {
			signout: {
				signout,
				isError: signoutError,
				isPending: signoutPending,
				isSuccess: signoutSuccess,
			},
			me: {
				me: refetchMe,
				isSuccess: meIsSuccess,
				isPending: meIsPending,
				isError: meIsError,
			},
		},
	};
};
