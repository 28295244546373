import type { DataType, EDataType, IMSResponse } from '@/types/global';
import { ErrorContext, ErrorResponsability } from '@/types/global';

import { ErrorCodeFR, ErrorContextFR, ErrorDataTypeFR, ErrorResponsabilityFR } from './translate';

export const ERROR_CODE_SEPARATOR = '|';

/**
 * @description
 * Base class for all errors
 *
 * Use a normed error message format
 * Every error message should be transformed into a coded string with ':' separator.
 * This is what the client will receive:
 * 1: NdlssError (always present)
 * 2 Responsability: Server, Client
 * 3 Context: Auth, Database, DtoValidation, Business, ...
 * 4 Data type: Customer, ConstructionSite, CollectConfig, Order, ...
 * 4 Error code: 0, 1, 2,
 */
export class NdlssError extends Error {
	override name = 'NdlssError';
	httpCode: number;

	constructor({
		httpCode = 500,
		responsability = ErrorResponsability.Server,
		context = ErrorContext.Other,
		params: parameters = {},
		dataType,
		code,
		disableLog = false,
	}: NdlssErrorConstructor) {
		const message: string = [
			'NdlssError',
			responsability,
			context,
			dataType ?? 'Global',
			code,
			JSON.stringify(parameters),
		].join(ERROR_CODE_SEPARATOR);

		super(message);

		this.httpCode = httpCode;

		if (!disableLog) {
			console.error(
				`🚫 Ndlss Error Throwed on context "${context}" of "${dataType?.toLowerCase()}", with code "${code}" and params "${JSON.stringify(
					parameters,
				)}"`,
			);
		}
	}

	public toIMSResponse(): IMSResponse {
		return {
			count: 0,
			message: this.message,
			status_code: this.httpCode,
			ro: undefined,
		};
	}

	private static blank(): NdlssError {
		return new NdlssError({
			httpCode: 500,
			context: ErrorContext.Other,
			code: '',
		});
	}

	private static getDataType(dataType: EDataType | DataType): string {
		return ErrorDataTypeFR[dataType as EDataType] ?? 'Inconnue';
	}

	private static getErrorContext(context: ErrorContext): string {
		return ErrorContextFR[context] ?? 'Inconnu';
	}

	private static getErrorResponsability(responsability: ErrorResponsability): string {
		return ErrorResponsabilityFR[responsability] ?? 'Inconnue';
	}

	private static getErrorMessage(context: ErrorContext, code: string, parameters: string): string {
		const messageInterpretation = ((ErrorCodeFR[context] as Record<string, (x: Record<string, any>) => string>) ?? {})[
			code
		];

		return messageInterpretation ? messageInterpretation(JSON.parse(parameters)) : 'Erreur inconnue';
	}

	static isNdlssError(error: Error): error is NdlssError {
		return error.name?.includes('Ndlss');
	}

	static interpret(error: Error): NdlssError {
		if (NdlssError.isNdlssError(error)) {
			const ndlssError = NdlssError.blank();
			ndlssError.httpCode = error.httpCode;
			ndlssError.message = error.message;
			ndlssError.name = error.name;

			return ndlssError;
		} else {
			return new NdlssError({
				httpCode: 500,
				context: ErrorContext.Other,
				code: '',
			});
		}
	}

	/**
	 * @description
	 * Get the error code from the error message and return information about it
	 * @param error Error to be interpreted
	 */
	static humanize(error: string): { responsability: string; context: string; dataType: string; message: string } {
		const [, responsability, context, dataType, code, parameters] = error.split(ERROR_CODE_SEPARATOR);

		return {
			responsability: NdlssError.getErrorResponsability(responsability as ErrorResponsability),
			context: NdlssError.getErrorContext(context as ErrorContext),
			dataType: NdlssError.getDataType(dataType as EDataType),
			message: NdlssError.getErrorMessage(context as ErrorContext, code ?? '', parameters ?? ''),
		};
	}
}

type NdlssErrorConstructor = {
	httpCode?: number;
	responsability?: ErrorResponsability;
	context?: ErrorContext;
	dataType?: EDataType;
	code: string;
	params?: Record<string, any>;
	disableLog?: boolean;
};
