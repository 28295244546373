import { useCountdown } from 'usehooks-ts';
import { InfoOrWarningCard } from './info-or-warning';
import { Button } from './ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from './ui/dialog';
import React from 'react';

type NewVersionModalProps = {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	lastVersion: string;
};

export const NewVersionModal: React.FC<NewVersionModalProps> = ({ open, lastVersion }) => {
	const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
		countStart: 15,
		intervalMs: 1000,
	});

	React.useEffect(() => {
		if (open) {
			startCountdown();
		} else {
			stopCountdown();
			resetCountdown();
		}
	}, [open]);

	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				//Do nothing
			}}
		>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Nouvelle version disponible</DialogTitle>
					<DialogDescription>
						Une nouvelle version de notre site est disponible. Pour profiter des dernières fonctionnalités et des
						derniers correctifs, nous allons recharger la page.
						<br />
						<br />
						<strong>Dernière version:</strong> {lastVersion}
						<br />
						<br />
						<InfoOrWarningCard
							type="warning"
							text="Attention: Vous allez perdre toutes les données non sauvegardées. Nous nous excusons pour la gêne occasionnée."
						/>
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button
						onClick={() => {
							window.location.reload();
						}}
						disabled={count > 0}
					>
						Recharger la page {count > 0 ? `(${count}s)` : ''}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
