import Watermark from '@uiw/react-watermark';

type WatermarkWrapperProperties = {
	display?: boolean;
	content?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const WatermarkWrapper: React.FC<WatermarkWrapperProperties> = ({ display = false, content = '', children }) => {
	if (display && content) {
		return (
			<Watermark content={content} className="min-h-screen w-screen">
				{children}
			</Watermark>
		);
	}

	return <>{children}</>;
};
