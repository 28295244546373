/* eslint-disable unicorn/prefer-top-level-await */
import { z } from 'zod';

import { createFileRoute } from '@tanstack/react-router';

const searchValidators = z.object({
	type: z.enum(['valorisation']).optional().catch('valorisation'),
});

export const Route = createFileRoute('/_dashboard/dashboard/reports/')({
	validateSearch: (search: Record<string, unknown>): z.infer<typeof searchValidators> => {
		return searchValidators.parse(search);
	},
});
