import type {
	ECollectCharacteristic,
	EOrderTakenFrom,
	EPlanningRegion,
	EPlanningType,
	ESlot,
	IKafkaQuery,
	LineCreateDto,
	ProductRo,
	ZoneRo,
} from '..';
import type { AddressCreateDto, AddressRo } from '../address';
import type { IContact, ISODate, NdlssPrice } from '../misc';
import { PRODUCT_FAMILY } from '../product';

export type CCPostponeDto = {
	date: ISODate;
	cc_admin_id: string[];
	cc_service_id: string[];
};

export type PostponeInformation = {
	from_day: ISODate;
	to_day: ISODate;
};

export type CCAdministrativeCreateDto = {
	title: string;
	address: AddressCreateDto;
	comment: string | null;
	from_date: ISODate;
	to_date: ISODate;
	collector_id: string[];
	execution_time_minutes: number;
	type: EPlanningType;
};
export type CCAdministrativeCreateActionDto = CCAdministrativeCreateDto & {
	family: typeof CC_FAMILY.ADMINISTRATIVE;
	status: CC_STATUS;
};

export type CCAdministrativeUpdateDto = Partial<Omit<CCAdministrativeCreateDto, 'type'>> & { status?: CC_STATUS };
export type CCAdministrativeUpdateActionDto = CCAdministrativeUpdateDto & {
	postpone_informations?: PostponeInformation[];
};

export type CCAdministrativeStatus = 'TO_PLAN' | 'PLANNED' | 'HAZARD' | 'FINISHED' | 'CANCELED';

export type CCAdministrativeRo = {
	id: string;
	title: string;
	address: AddressCreateDto;
	comment: string | null;
	from_date: ISODate;
	to_date: ISODate;
	truck_id: string[];
	collector_id: string[];
	execution_time_minutes: number;
	family: typeof CC_FAMILY.ADMINISTRATIVE;
	status: CCAdministrativeStatus;
	type: EPlanningType;
	region: EPlanningRegion;
	archived: boolean;
	created_by: string;
	created_at: ISODate;
	updated_at: ISODate;
	postpone_informations: PostponeInformation[];
};

export type CCAdministrativeQuery = IKafkaQuery & {
	day?: ISODate;
	type?: EPlanningType;
	region?: EPlanningRegion;
	min_date?: ISODate;
	max_date?: ISODate;
	status?: CCAdministrativeStatus;
	statuses?: CCAdministrativeStatus[];
};

/*********************************************************************/

export type CCServiceCreateActionDto = {
	id: string;
	comment?: string;
	to_date: ISODate;
	freeze: boolean;
	price: NdlssPrice;
	prepaid: boolean;
	from_date: ISODate;
	type: EPlanningType;
	order_id: string[];
	order_number: string;
	customer_id: string[];
	zone: CollectConfigZone;
	address: AddressCreateDto;
	products: ProductInCCOrCO[];
	status: CC_STATUS;
	family: CC_FAMILY;
	characteristics: ECollectCharacteristic[];
	waiting_time_minutes?: number;
	retrieval_date?: ISODate;
	execution_time_minutes?: number;
	construction_site_contact?: Array<IContact>;
	log_contact?: Array<IContact>;
	construction_site_id: string[];
	already_available_date?: AlreadyAvailableDate;
	address_shipping?: AddressCreateDto;
	invoicedOnE1?: boolean;
	invoicedOnE2?: boolean;
	taken_from?: EOrderTakenFrom;
	presta_id?: string[];
	dumpster_on_site_id?: string[];
};

export type CCServiceUpdateDto = {
	characteristics?: ECollectCharacteristic[];
	comment?: string;
	from_date?: ISODate;
	to_date?: ISODate;
	already_available_date?: AlreadyAvailableDate;
	waiting_time_minutes?: number;
	retrieval_date?: ISODate;
	log_contact?: Array<IContact>;
	construction_site_contact?: Array<IContact>;
	lines?: LineCreateDto[];
	execution_time_minutes?: number;
	patch_origin?: CC_PATCH_ORIGIN;
	patch_reason?: string;
	invoicedOnE1?: boolean;
	invoicedOnE2?: boolean;
	presta_id?: string[];
	dumpster_on_site_id?: string[];
};

export type SplittedInformations = {
	products: ProductInCCOrCO[];
	status: CC_STATUS;
	idx: number;
};

export type CCServiceUpdateActionDto = Omit<CCServiceUpdateDto, 'lines'> & {
	splitted_informations?: SplittedInformations[];
	price?: NdlssPrice;
	products?: ProductInCCOrCO[];
	invoice_id?: string[];
	postpone_informations?: PostponeInformation[];
	status?: CC_STATUS;
	presta_id?: string[];
};

export type CollectConfigCancelDto = CollectConfigPatchTracker & {
	do_not_invoice?: boolean;
};

export type CCServiceSplitDto = {
	lines_by_collect: Array<LineCreateDto[]>;
};

export type AlreadyAvailableDate = {
	from_date: ISODate; // Date de début de disponibilité
	to_date: ISODate; // Date de fin de disponibilité
};

export const isAlreadyAvailable = (
	cc: CCServiceRo,
): cc is CCServiceRo & { already_available_date: AlreadyAvailableDate } => {
	return (
		cc.already_available_date !== null &&
		typeof (cc.already_available_date as CCServiceRo)?.from_date === 'string' &&
		typeof (cc.already_available_date as CCServiceRo)?.to_date === 'string'
	);
};

export type CCServiceRo = {
	id: string;
	title?: string | null;

	number: string;
	order_number: string | null;

	status: CC_STATUS;
	family: CC_FAMILY;

	type: EPlanningType;
	region: EPlanningRegion;
	zone: CollectConfigZone;
	address: AddressRo;

	address_shipping: AddressRo | null;
	address_invoice: AddressRo | null;

	characteristics: ECollectCharacteristic[];
	comment: string | null;

	from_date: ISODate;
	to_date: ISODate;
	already_available_date: AlreadyAvailableDate | null;
	waiting_time_minutes: number | null; //Only for Attente de chargement
	retrieval_date: ISODate | null; //Only for Dumpster Deposit

	log_contact: Array<IContact>;
	construction_site_contact: Array<IContact>;

	prepaid: boolean;

	freeze: boolean;

	products: ProductInCCOrCO[];
	price: NdlssPrice;

	splitted_informations: SplittedInformations[];
	postpone_informations: PostponeInformation[];

	execution_time_minutes: number;

	patch_origin: CC_PATCH_ORIGIN | null;
	patch_reason: string | null;

	order_id: string[];
	collector_id: string[];
	invoice_id: string[];
	construction_site_id: string[];
	customer_id: string[];
	presta_id: string[];
	dumpster_on_site_id: string[];

	invoicedOnE1: boolean;
	invoicedOnE2: boolean;

	archived: boolean;
	created_by: string;
	created_at: ISODate;
	updated_at: ISODate;
};

export type ProductInCCOrCO = {
	quantity: number;
} & Pick<
	ProductRo,
	| 'id'
	| 'family'
	| 'internal'
	| 'operational'
	| 'name'
	| 'subname'
	| 'price'
	| 'vat_rate_percentage'
	| 'trash_type'
	| 'volume_m3'
	| 'main_photo'
	| 'description'
	| 'billing_branch'
> & { zone: CollectConfigZone };

export const CC_FAMILY = {
	...PRODUCT_FAMILY,
	COLLECT_DUMPSTER_ROTATION: 'COLLECT_DUMPSTER_ROTATION',
	// COLLECT_DUMPSTER_ROUND_TRIP : 'COLLECT_DUMPSTER_ROUND_TRIP', //TODO: Add later
	ADMINISTRATIVE: 'ADMINISTRATIVE',
} as const;
export type CC_FAMILY = (typeof CC_FAMILY)[keyof typeof CC_FAMILY];

export const CC_STATUS = {
	WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
	ORDER_TO_PAY: 'ORDER_TO_PAY',
	TO_PREPARE: 'TO_PREPARE',
	TO_PLAN: 'TO_PLAN',
	PLANNED: 'PLANNED',
	CANCELED: 'CANCELED',
	FINISHED: 'FINISHED',
	HAZARD: 'HAZARD',
	SPLITTED: 'SPLITTED',
} as const;
export type CC_STATUS = (typeof CC_STATUS)[keyof typeof CC_STATUS];

export const WAITING_CC_STATUS = [CC_STATUS.WAITING_FOR_APPROVAL, CC_STATUS.ORDER_TO_PAY] as const;

export interface CollectConfigQuery extends IKafkaQuery {
	min_date?: string; // filters collects scheduled after min_date
	max_date?: string; // filters collects scheduled before max_date
	day?: string; // filters collects scheduled on day
	type?: EPlanningType;
	region?: EPlanningRegion;
	order_id?: string; // filters collects of a given order
	family?: CC_FAMILY; // filters collects of a given family
	families?: CC_FAMILY[]; // filters collects of given families
	status?: CC_STATUS; // filters collects of a given status
	statuses?: CC_STATUS[]; // filters collects of given statuses
	customer_id?: string; // filters collects of a given customer
	construction_site_id?: string; // filters collects of a given cons site
	no_delivery?: boolean; // removes collects without delivery
	// no_administrative?: boolean; // removes collects without administrative
	uncancelled?: boolean; // removes cancelled collects
	paid?: boolean; // removes collects which order is not paid yet
	not_finished?: boolean; // removes finished/hazard collects
	prepaid?: boolean;
	already_available_date?: ISODate; //Will get the already available collect for the given date
	invoicedOnE1?: boolean; // Will get the cc invoiced on E1
	invoicedOnE2?: boolean; // Will get the cc invoiced on E2
	orderByCollectDateAsc?: boolean; // Will order the collects by collect date asc
	orderByCollectDateDesc?: boolean; // Will order the collects by collect date desc
	presta?: 'only' | 'include'; // Will get presta cc included or only, otherwise will exclude presta cc if undefined
	presta_id?: string; // Will get the cc with the given presta_id (overwrites presta)
	dumpster_on_site_id?: string;
	dumpster_on_site_ids?: string[];
}

export const CC_PATCH_ORIGIN = {
	INTERNAL: 'INTERNAL',
	CLIENT_REQUEST: 'CLIENT_REQUEST',
} as const;
export type CC_PATCH_ORIGIN = (typeof CC_PATCH_ORIGIN)[keyof typeof CC_PATCH_ORIGIN];

export type CollectConfigPatchTracker = {
	patch_origin: CC_PATCH_ORIGIN;
	patch_reason: string;
};

export type CollectConfigZone = Pick<ZoneRo, 'id' | 'metropole' | 'name'> & {
    display_name?: string;
};

export type CCRo = CCServiceRo | CCAdministrativeRo;
export const COLLECT_CONFIG_NUMBER_PREFIX = 'CO';
export const COLLECT_CONFIG_PATCH_REASON_MIN_LENGTH = 10;
export const DEADLINE_TO_UPDATE = { day: 0, setHours: 22, setMinutes: 0, setSeconds: 0 }; //⚠️ It's in UTC
export const DEADLINE_TO_CREATE = { day: 0, setHours: 22, setMinutes: 0, setSeconds: 0 }; //⚠️ It's in UTC

export type CCDensityQuery = {
	day: string;
	type: EPlanningType;
};

export type CCDensityData = {
	collectsNumber: number;
	volumeInM3: number;
};

export type CCDensityGeneralInfo = {
	totalCollects: number;
	totalVolumeInM3: number;
};

export type CCDensityRo = Record<ESlot, CCDensityData> & CCDensityGeneralInfo;
