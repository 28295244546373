import { lazy, Suspense } from 'react';

import type { AuthContextType } from '@/context/auth';
import type { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';

const TanstackRouterDevtools =
	process.env.NODE_ENV === 'production'
		? () => null
		: lazy(() => import('@tanstack/router-devtools').then((module) => ({ default: module.TanStackRouterDevtools })));

const TanstackQueryDevtools =
	process.env.NODE_ENV === 'production'
		? () => null
		: lazy(() => import('@tanstack/react-query-devtools').then((module) => ({ default: module.ReactQueryDevtools })));

interface RouterContext {
	queryClient: QueryClient;
	auth: AuthContextType;
}

export const Route = createRootRouteWithContext<RouterContext>()({
	component: () => (
		<>
			<Outlet />
			<Suspense fallback={null}>
				<TanstackRouterDevtools />
				<TanstackQueryDevtools />
			</Suspense>
		</>
	),
});
