import { ndlssEndpoints } from '@/sdk/endpoints';
import { httpClient } from '@/sdk/sdk';
import type {
	CustomerGetMyKpiDataQuery,
	CustomerGetMyKpiDataRo,
	CustomerGetMyRevaloQuery,
	CustomerGetMyValorisationRo,
	CustomerRo,
	IBbngResponse,
} from '@/types/global';
import { queryOptions } from '@tanstack/react-query';

import { queryKeys } from '../keys';

export const useGetKpiDataMeOptions = (enabled = true, query: CustomerGetMyKpiDataQuery) => {
	return queryOptions({
		queryKey: [queryKeys.customers.kpiData, { ...query }],
		queryFn: async () => {
			const data = await httpClient.get<IBbngResponse<CustomerGetMyKpiDataRo>>(ndlssEndpoints.customers.kpiData, {
				params: {
					...query,
				} satisfies CustomerGetMyKpiDataQuery,
			});
			return data.data.data;
		},
		enabled,
	});
};

export const useGetKpiRevalorisationMeOptions = (enabled = true, query: CustomerGetMyRevaloQuery) => {
	return queryOptions({
		queryKey: [queryKeys.customers.revalorisation, { ...query }],
		queryFn: async () => {
			const data = await httpClient.get<IBbngResponse<CustomerGetMyValorisationRo[]>>(
				ndlssEndpoints.customers.revalorisation,
				{
					params: {
						...query,
					} satisfies CustomerGetMyRevaloQuery,
				},
			);
			return data.data.data;
		},
		enabled,
	});
};

export const useGetCustomerMeOptions = (enabled = true, id: string) => {
	return queryOptions({
		queryKey: [queryKeys.customers.me, id],
		queryFn: async () => {
			const data = await httpClient.get<IBbngResponse<CustomerRo>>(ndlssEndpoints.customers.me(id));
			return data.data.data;
		},
		enabled,
		staleTime: 1000 * 60 * 5 /* 5 minutes */,
	});
};

export const useVerifySiretOptions = (enabled = true, siret: string) => {
	return queryOptions({
		queryKey: [queryKeys.customers.verify, siret],
		queryFn: async () => {
			const data = await httpClient.get<IBbngResponse<boolean>>(ndlssEndpoints.customers.verify(siret));
			return data.data.data;
		},
		enabled,
	});
};
