export const queryKeys = {
	auth: {
		register: 'auth-register',
		initiateLogin: 'auth-initiate-login',
		login: 'auth-login',
		verifySession: 'auth-verify-session',
		signout: 'auth-signout',
	},
	users: {
		me: 'users-me',
		getAll: 'users-get-all',
		create: 'users-create',
		updateById: 'users-update-by-id',
		getById: 'users-get-by-id',
	},
	collects: {
		getAll: 'collects-get-all',
		dumpsterOnSite: 'collects-dumpster-on-site',
	},
	ccs: {
		retrieve: 'ccs-retrive',
		getAll: 'ccs-get-all',
		cancel: 'ccs-cancel',
		getById: 'ccs-get-by-id',
	},
	customers: {
		me: 'customers-me',
		kpiData: 'customers-kpi-data',
		revalorisation: 'customers-revalorisation',
		updateById: 'customers-update-by-id',
		create: 'customers-create',
		verify: 'customers-verify',
		invitation: 'customers-invitation',
		revalorisationExport: 'customers-revalorisation-export',
		removeUser: 'customers-remove-user',
	},
	constructionSites: {
		create: 'constructionSites-create',
		retrieve: 'constructionSites-retrieve',
		getAll: 'constructionSites-get-all',
		getById: 'constructionSites-get-by-id',
		updateById: 'constructionSites-update-by-id',
		archiveById: 'constructionSites-archive-by-id',
	},
	documents: {
		download: 'documents-download',
	},
	zones: {
		getAll: 'zones-get-all',
	},
	products: {
		getAll: 'products-get-all',
		getCancelationPrice: 'products-get-cancelation-price',
		retrieve: 'products-retrieve',
	},
	discounts: {
		getAll: 'discounts-get-all',
	},
	orders: {
		create: 'orders-create',
		calculatePrice: 'orders-calculate-price',
		nextAvailableDay: 'orders-next-available-day',
		resendLink: 'orders-resend-link',
	},
	invoices: {
		getAll: 'invoices-get-all',
		download: 'invoices-download',
	},
	pappers: {
		search: 'pappers-search',
	},
	maintenance: {
		check: 'maintenance-check',
	},
	versionning: {
		check: 'versionning-check',
	},
} as const;
