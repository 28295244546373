import type { AddressCreateDto, AddressEditDto, AddressRo } from '../address';
import type { CollectEmptying, CollectService } from '../collect';
import type { CCServiceRo } from '../collect-config';
import type { ConstructionSiteRo } from '../construction-site';
import type { DocumentCreateDto } from '../document';
import type { RelationsDto } from '../dto';
import type { GoogleDriveRo } from '../google-drive';
import type { IKafkaQuery } from '../kafka';
import type { LandfillRo, RecyclingCharacteristicsRo } from '../landfill';
import type { BillingBranch, CREATED_FROM, ETrashType, IContact, ISODate } from '../misc';
import type { EZohoOrganization, ZohoContactPerson } from '../zoho';

type PaymentCurrency = any;
type MandateStatus = any;

export type CustomerCreateDto = {
	name: string;
	internal_name?: string;
	shipping_addresses?: Array<AddressCreateDto>;
	invoice_addresses: Array<AddressCreateDto>;
	membership?: EMembershipType;
	origin?: ECustomerOrigin;
	registration?: ISODate;
	share_capital?: number;
	siren?: string;
	siret?: string;
	vat?: string;
	naf?: string;
	billing_mode?: EBillingMode;
	billing_bundle?: EBillingBundle;
	billing_shipment?: EBillingShipment;
	outstanding_amount?: number;
	outstanding_max_amount?: number;
	payment_time?: number;
	revenue?: number;
	revenue_year?: number;
	headquarters?: AddressCreateDto;
	type?: string;
	is_retailer?: boolean;
	contact?: ICustomerContact[];
	admin_id: Array<string>;
	category: ECustomerCategory;
	blocked: boolean;
	mandatory_order_sheet: boolean;
	documents?: Array<DocumentCreateDto>;
	previous_id_zoho_customer?: string;
	comment?: string;
	created_from?: CREATED_FROM;
};
export type CustomerCreateActionDto = Omit<CustomerCreateDto, 'documents'> & {
	id?: string;
	document_id?: string[];
	user_id?: string[];
};

export type CustomerEditDto = RelationsDto<CustomerRo> & {
	name?: string;
	internal_name?: string;
	shipping_addresses?: Array<AddressEditDto>;
	invoice_addresses?: Array<AddressEditDto>;
	membership?: EMembershipType;
	origin?: ECustomerOrigin;
	registration?: ISODate;
	share_capital?: number;
	siren?: string;
	siret?: string;
	vat?: string;
	naf?: string;
	billing_mode?: EBillingMode;
	billing_bundle?: EBillingBundle;
	billing_shipment?: EBillingShipment;
	outstanding_amount?: number;
	outstanding_max_amount?: number;
	payment_time?: number;
	revenue?: number;
	revenue_year?: number;
	headquarters?: AddressEditDto;
	hubspot_id?: string | null;
	type?: string;
	is_retailer?: boolean;
	contact?: ICustomerContact[];
	defaultPaymentMethod?: string;
	category?: ECustomerCategory;
	blocked?: boolean;
	zoho_modifications?: CustomerZohoModifications;
	mandatory_order_sheet?: boolean;
	documents?: Array<DocumentCreateDto>;
	previous_id_zoho_customer?: string;
	comment?: string;
};

export type CustomerEditActionDto = Omit<CustomerEditDto, 'documents' | 'connect' | 'disconnect'> & {
	has_legacy_deliveries?: boolean;
	id_gocardless_customer_E1?: string;
	id_gocardless_customer_E2?: string;
	id_zoho_customer?: string;
	document_id?: Array<string>;
	admin_id?: Array<string>;
	user_id?: Array<string>;
	archived?: boolean;
};

export interface CustomerSetupMandateDto {
	iban: string;
}

export type CustomerEditFromZohoCustomerIdDto = CustomerEditDto & {
	id_zoho_customer: string;
	zoho_contact_persons?: ZohoContactPerson[];
};

export type CustomerZohoModifications = CustomerEditDto & {
	error?: string; // Joi validation error message
};

export interface CustomerRo {
	id: string;
	name: string;
	internal_name: string | null;
	shipping_addresses: Array<AddressRo>;
	invoice_addresses: Array<AddressRo>;
	membership: EMembershipType | null;
	origin: ECustomerOrigin;
	registration: ISODate | null;
	share_capital: number | null;
	siren: string | null;
	siret: string | null;
	vat: string | null;
	type: string | null;
	naf: string | null;
	hubspot_id: string | null;
	revenue?: number | null;
	revenue_year?: number | null;
	billing_mode: EBillingMode;
	billing_bundle: EBillingBundle;
	billing_shipment: EBillingShipment;
	billing_organization: EZohoOrganization;
	outstanding_amount: number | null;
	outstanding_max_amount: number | null;
	payment_time: number;
	headquarters: AddressCreateDto | null;
	is_retailer: boolean;
	comment: string | null;

	admin_id: Array<string>;
	document_id: Array<string>;
	user_id: Array<string>;

	contact: ICustomerContact[];
	id_gocardless_customer_E1: string | null;
	id_gocardless_customer_E2: string | null;
	id_zoho_customer: string | null;
	previous_id_zoho_customer?: string | null;
	created_by: string;
	created_at: ISODate;
	created_from: CREATED_FROM;
	updated_at: ISODate;
	payment_methods: Array<CustomerPaymentMethod>;
	category: ECustomerCategory;
	blocked: boolean;
	mandatory_order_sheet: boolean;
	zoho_modifications: CustomerZohoModifications | null;
	has_legacy_deliveries: boolean;
	archived: boolean;
}

export interface CustomerPaymentMethod<Details = Record<string, any>> {
	id: string;
	type: EPaymentMethodType;
	details: Details;
	is_default: boolean;
}

export interface CustomerAddPaymentMethodDto<Details = Record<string, any>> {
	type: EPaymentMethodType;
	details: Details;
}

export interface CustomerPayment<Details = Record<string, any>> {
	details: Details;
	charged_in: number;
	amount: number;
	currency: PaymentCurrency;
	reference?: string;
	description?: string;
}

export enum EBillingMode {
	INSTANT = 'INSTANT',
	AFTER_COLLECT = 'AFTER_COLLECT',
	END_OF_MONTH = 'END_OF_MONTH',
}

export enum EMembershipType {
	IVORY = 'IVORY',
	SILVER = 'SILVER',
	GOLD = 'GOLD',
}

export enum ECustomerOrigin {
	BIG_BAG_N_GO = 'BIG_BAG_N_GO',
	SOS_BENNES = 'SOS_BENNES',
	ENDLESS = 'ENDLESS',
}

export enum EPaymentMethodType {
	GOCARDLESS = 'GOCARDLESS',
	STRIPE = 'STRIPE',
	CHECK = 'CHECK',
	TRANSFER = 'TRANSFER',
}

export enum EBillingBundle {
	BY_CONSTRUCTION_SITE = 'BY_CONSTRUCTION_SITE',
	BY_COLLECT = 'BY_COLLECT',
	GLOBAL = 'GLOBAL',
	GLOBAL_BY_CONSTRUCTION_SITE = 'GLOBAL_BY_CONSTRUCTION_SITE',
}

export enum EBillingShipment {
	EMAIL = 'EMAIL',
	LETTER = 'LETTER',
}

export type CustomerExportRevalorisationDto = {
	customer_id: string;
	construction_site_ids: string[];
	from_date?: ISODate;
	to_date?: ISODate;
};

export type CustomerExportRevalorisationLigne = {
	Shipment_Date: string;
	Provider: string;
	Order_Number: string;
	Construction_Site: string;
	Sender_Street_Number: string;
	Sender_Postal_Code: string;
	Sender_City: string;
	Waste_Code: string;
	Waste_Nature: string;
	Waste_Family: string;
	Declassified_Dumpster: string;
	Dumpster_Type: string;
	Tonnage: string;
	Declassified_Tonnage: string;
	Name: string;
	Address: string;
	Receipt_Number: string;
	Weighin_Slip_Number: string;
	Outlet_Name: string;
	Outlet_Address: string;
	Recycling: string;
	Reuse_Percentage: string;
	Recycling_Percentage: string;
	Valorisation_Energy_Recovery_Percentage: string;
	Disposal_Percentage: string;
	Overall_Recovery_Percentage: string;
};

export interface GetAllCustomerQuery extends IKafkaQuery {
	billing_mode?: EBillingMode;
	billing_shipment?: EBillingShipment;
	id_zoho_customer?: string;
	category?: ECustomerCategory;
	blocked?: string;
	status?: EMembershipType;
	billingOrganization?: EZohoOrganization;
	hasActiveMandate?: boolean;
}

export enum ECustomerCategory {
	INDIVIDUAL = 'INDIVIDUAL',
	PRO = 'PRO',
	PRO_TO_VALIDATE = 'PRO_TO_VALIDATE',
}

export type ICustomerContact = Partial<IContact> & {
	is_primary: boolean;
	is_invoice: boolean;
	id_zoho_contact_person?: string;
};

export type CustomerCreateMissingGoogleDriverDto = {
	month: number;
	year: number;
};

export type CustomerGoogleDriveLinkEmailRo = {
	googleDrives: GoogleDriveRo[];
	customersWithoutEmail: CustomerRo[];
};

/**
 * GO CARDLESS
 **/
export type CustomerMandateFlowCreateDto = {
	customer_id: string;
	email: string;
	firstname?: string;
	lastname?: string;
	createOnE1: boolean;
	createOnE2: boolean;
};

export type CustomerMandateFlowCreateRo = {
	E1Url?: string;
	E2Url?: string;
};

export type CustomerMandateRo = {
	id: string;
	status: MandateStatus;
	created_at: ISODate;
	updated_at: ISODate;
	id_gocardless_mandate: string;
	customer_id: string;
	customer?: Pick<CustomerRo, 'name' | 'id'> | null;
	billing_branch: BillingBranch;
	next_possible_charge_date: string | null; // YYYY-MM-DD
	metadata: Record<string, any> | null;
};

export type CustomerMandateCreationActionDto = {
	customer_id: string;
	id_gocardless_mandate: string;
	status: MandateStatus;
	billing_branch: BillingBranch;
	next_possible_charge_date?: string; // YYYY-MM-DD
	metadata?: Record<string, any> | null;
};

export type CustomerMandateEditActionDto = {
	status?: MandateStatus;
	next_possible_charge_date?: string; // YYYY-MM-DD
	metadata?: Record<string, any>;
};

export interface GetAllCustomerMandateQuery extends IKafkaQuery {
	status?: MandateStatus;
	statuses?: MandateStatus[];
	customer_id?: string;
	billing_branch?: BillingBranch;
}

export type CustomerInvitationData = {
	customer_id: string;
	customer_name: string;
	customer_siret: string;
	user_id: string;
	user_name: string;
	user_email: string;
};

/**
 * Custom create invitation dto for admins.
 * For users, user_id is taken from JWT.
 */
export type CustomerCreateInvitationAsAdminDto = {
	user_id: string;
};

export type CustomerCreateRevalorisationExportDto = {
	customer_id: string;
	construction_site_ids: string[];
	from_date?: ISODate;
	to_date?: ISODate;
};

export type CustomerCreateRevalorisationExportRo = {
	customer: CustomerRo;
	constructionSites: ConstructionSiteRo[];
	ccs: CCServiceRo[];
	landfills: LandfillRo[];
	recyclingCharacteristics: RecyclingCharacteristicsRo[];
	collects: CollectService[];
	emptyingCollects: CollectEmptying[];
	from_date?: ISODate;
	to_date?: ISODate;
};

export type CustomerOrderConfirmationEmailCreateActionDto = {
	email: string;
	customer_id: string;
};

export type CustomerOrderConfirmationEmailRo = {
	id: string;
	email: string;
	customer_id: string;
	created_at: ISODate;
};

export type CustomerGetMyKpiDataRo = {
	numberOfCollects: number;
	numberOfActiveConstructionSites: number;
	bigBagVolume: number;
	dumpsterVolume: number;
};

export type CustomerGetMyValorisationRo = {
	trashType: ETrashType;
	reusageVolume: number;
	recyclingVolume: number;
	eliminationVolume: number;
	energeticValorisationVolume: number;
	noInformationVolume: number;
};

export type CustomerRemoveUserDto = {
    customer_id: string;
    user_id: string;
};