import { environmentVar } from '@/env';
import * as Sentry from '@sentry/react';
export * as Sentry from '@sentry/react';

import { GOD_MODE_KEY } from './constants';

const SENTRY_DSN = environmentVar.VITE_SENTRY_DSN;
const SENTRY_ENV = environmentVar.VITE_SENTRY_ENVIRONMENT;
const SENTRY_DEBUG = environmentVar.VITE_SENTRY_DEBUG === true;
const APP_VERSION = environmentVar.VITE_APP_VERSION;
const NDLSS_API_URL = environmentVar.VITE_API_URL;
const SENTRY_SUCCESS_RECORD_RATE = Number.parseFloat(environmentVar.VITE_SENTRY_SUCCESS_RECORD_RATE || '0.1');
const SENTRY_ERROR_RECORD_RATE = Number.parseFloat(environmentVar.VITE_SENTRY_ERROR_RECORD_RATE || '1');

// Log all sentry variable if god mode is enabled
if (window.localStorage.getItem(GOD_MODE_KEY) === 'true') {
	console.log('SENTRY_DSN', SENTRY_DSN);
	console.log('SENTRY_ENV', SENTRY_ENV);
	console.log('SENTRY_DEBUG', SENTRY_DEBUG);
	console.log('APP_VERSION', APP_VERSION);
	console.log('NDLSS_API_URL', NDLSS_API_URL);
	console.log('SENTRY_SUCCESS_RECORD_RATE', SENTRY_SUCCESS_RECORD_RATE);
	console.log('SENTRY_ERROR_RECORD_RATE', SENTRY_ERROR_RECORD_RATE);
}

export const initializeSentry = () => {
	Sentry.init({
		dsn: SENTRY_DSN,
		release: `customer-area-${SENTRY_ENV}-${APP_VERSION}`,
		debug: SENTRY_DEBUG,
		environment: SENTRY_ENV,
		enabled: SENTRY_DEBUG,
		integrations: [
			Sentry.browserTracingIntegration({
				tracePropagationTargets: [NDLSS_API_URL],
			}),
			Sentry.replayIntegration({
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false,
				networkDetailAllowUrls: [NDLSS_API_URL],
				maxReplayDuration: 10 * 60 * 1000, // max 10 minutes of replay duration
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 0.1, //  Capture 10% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [NDLSS_API_URL],
		// Session Replay
		replaysSessionSampleRate: SENTRY_SUCCESS_RECORD_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: SENTRY_ERROR_RECORD_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

		/**
		 *
		 * @param event The error or message event generated by the SDK.
		 * @param hint Event metadata useful for processing.
		 * @returns A new event that will be sent | null.
		 */
		beforeSend(event) {
			// skip axios cancellation error, it is an expected error from aborting a request, should not be sent to Sentry
			if (event.message === 'CanceledError') {
				return null;
			}

			return event;
		},
	});
};
