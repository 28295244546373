import { Suspense } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

import { QueryErrorResetBoundary } from '@tanstack/react-query';

import { Button } from './ui/button';

type SuspenseWrapperProperties = {
	loader: React.ReactNode;
	component: React.ReactNode;
	resetComponent?: (properties: FallbackProps) => React.ReactNode;
};

export const SuspenseWrapper: React.FC<SuspenseWrapperProperties> = ({ loader, component, resetComponent }) => {
	return (
		<QueryErrorResetBoundary>
			{({ reset }) => (
				<ErrorBoundary
					fallbackRender={({ error, resetErrorBoundary }) => {
						if (resetComponent) {
							return resetComponent({ error, resetErrorBoundary });
						}
						return (
							<div>
								Une error est survenue! <Button onClick={() => resetErrorBoundary()}>Reessayer</Button>
								<pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
							</div>
						);
					}}
					onReset={reset}
				>
					<Suspense fallback={loader}>{component}</Suspense>
				</ErrorBoundary>
			)}
		</QueryErrorResetBoundary>
	);
};
