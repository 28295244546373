import type { DateObjectUnits } from "luxon";
import { DateTime } from "luxon";

type MomentSetObject = any;

/**
 * order can be cancelled until 17h00 (Europe/Paris) the day before
 */
export const MAX_DAY_LIMIT_FOR_ORDER_CANCELLATION = 1;
export const MAX_TIME_LIMIT_FOR_ORDER_CANCELLATION: MomentSetObject = {
	hour: 17,
	minute: 0,
	second: 0,
	millisecond: 0,
};

export const MAX_TIME_LIMIT_FOR_ORDER_CANCELLATION_TEXT = DateTime.now().setZone("Europe/Paris").set(MAX_TIME_LIMIT_FOR_ORDER_CANCELLATION).toFormat("HH:mm");

/**
 * For day slot, custom start time can be set, starting from 5h00
 */
export const MIN_START_TIME_ORDER_POST_IVORY: DateObjectUnits = {
	hour        : 5,
	minute      : 0,
	second      : 0,
	millisecond : 0
}

/**
  * For day slot, custom start time can be set, until 17h00
 */
export const MAX_START_TIME_ORDER_POST_IVORY: DateObjectUnits = {
	hour        : 17,
	minute      : 0,
	second      : 0,
	millisecond : 0
}
