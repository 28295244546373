/* eslint-disable unicorn/prevent-abbreviations */
import type { CCAdministrativeRo, CCServiceRo } from '../collect-config';
import type { CollectorRo } from '../collector';
import type { CustomerRo } from '../customer';
import type { LandfillRo } from '../landfill';
import type { ISODate } from '../misc';
import type { TruckRo } from '../truck';
import type { VRPBBResponse, VRPDumpsterResponse, VRPInstance } from '../vrp-algorithm';

export const PLANNING_DATE_FORMAT = 'YYYY-MM-DD';
export const PLANNING_CALCULATE_KEY_BASE = 'PLANNING:CALCULATE:';
export const PLANNING_CALCULATE_KEY_PUBLISHED = 'PLANNING:CALCULATE-PUBLISHED';
export const PLANNING_VRP_KEY_BASE = 'PLANNING:VRP:';

/**
 * BASE TYPE
 */
export type BaseType = {
	day: ISODate;
	region: EPlanningRegion;
	type: EPlanningType;
};

export const EPlanningCalculMethod = {
	Classic: 'Classic',
	Custom: 'Custom',
	CustomWithoutTime: 'CustomWithoutTime',
	Manual: 'Manual',
} as const;
export type EPlanningCalculMethod = (typeof EPlanningCalculMethod)[keyof typeof EPlanningCalculMethod];

export type PlanningGeneralConfig = {
	dumpster_deposit_priority: number;
	dumpster_removal_priority: number;
	maximum_volume: number;
};

export type PlanningShiftConfig = {
	start_date: ISODate;
	end_date: ISODate;
};

/**
 * QUERY
 */
export type PlanningMeQuery = {
	day: ISODate;
};
export type PlanningGetAllQuery = BaseType & {
	redis_version_key?: string;
};

/**
 * FRONT DTO
 */
export type PlanningCalculateDto = BaseType & {
	general_config: PlanningGeneralConfig;
	parameters: PlanningParameters[];
	cc_service_to_plan_id: string[];
	cc_administrative_to_plan_id: string[];
	clusters?: Record<string /** TRUCK_ID */, string[] /** CC_ID */>;
	redis_version_key?: string;
	calcul_method: EPlanningCalculMethod;
	end_slot_margin_minutes?: number; // margin in minutes to add to the end of all steps
};
export type PlanningParameters = {
	planning_id: string;
	ccsService: string[];
	ccsAdministrative: string[];
	shift_config: PlanningShiftConfig;
	truck_id: string;
	collector_id: string;
};
export type PlanningSaveDto = BaseType & {
	redis_version_key: string;
};
export type PlanningSaveDtoBack = BaseType & {
	redis_version_key: string;
	redis_plannings: PlanningRo[]; // set in saga controller
};
export type PlanningUnassignDto = BaseType & {
	unassigned_planning_id: string[];
};
export type PlanningCreateDto = BaseType;

export type PlanningFlattenDto = {
	truckId: string;
	collectorId: string;
};
export type PlanningGetInstanceDto = {
	ids: string[];
	isDraft: boolean;
};

export type PlanningManualShiftDto = BaseType & {
	parameters: PlanningParameters;
	ccAndEmptyingIdsSorted: IdAndSplittedIdx[];
	emptyingSteps: PlanningShiftStepManualEmptying[];
	redis_version_key?: string;
};
export type IdAndSplittedIdx = {
	id: string;
	splitted_idx?: number;
};

/**
 * ACTION DTO
 */
export type PlanningCreateActionDto = BaseType & {
	id?: string;
	general_config: PlanningGeneralConfig;
	shift_config: PlanningShiftConfig;
	shift: PlanningShift;
	automatic: boolean;
	calculated_at: ISODate;
	dayISO: ISODate;
	appears_on_list?: boolean;
	truck_id: string[];
	collector_id: string[];
	saved_at?: ISODate;
};

export type PlanningUpdateActionDto = {
	general_config?: PlanningGeneralConfig;
	shift_config?: PlanningShiftConfig;
	shift?: PlanningShift;
	automatic?: boolean;
	calculated_at?: ISODate;
	truck_id?: string[];
	collector_id?: string[];
	flattened_info?: PlanningFlattenedInfo | null;
	archived?: boolean;
	saved_at?: ISODate | null;
	calcul_method?: EPlanningCalculMethod;
};

export type PlanningFlattenedInfo = {
	truck: TruckRo;
	collector: CollectorRo;
};

export type PlanningUnassignCCDto = BaseType & {
	ccId: string;
	is_splitted: boolean;
	splitted_idx?: number;
};

export type PlanningRo = BaseType & {
	id: string;
	general_config: PlanningGeneralConfig;
	shift_config: PlanningShiftConfig;
	shift: PlanningShift;
	shift_hash: string;
	automatic: boolean;
	//These values are for info regarding the published version.
	calculated_at?: ISODate;
	dayISO: ISODate;
	truck_id: string[];
	collector_id: string[];
	flattened_info: PlanningFlattenedInfo;
	archived: boolean;
	created_by: string;
	created_at: ISODate;
	updated_at: ISODate;
	saved_at: ISODate | null;
	calcul_method: EPlanningCalculMethod;
};

export type CalculatedPlanningRo = PlanningRo & {
	planning_version_description: PlanningVersionDescriptionRo;
};

export type PlanningShift = {
	distance: number; // km
	duration: string; //HH:mm
	start_date: ISODate;
	end_date: ISODate;
	steps_service: PlanningShiftStepService[];
	steps_break: PlanningShiftStepBreak[];
	steps_driver: PlanningShiftStepDriver[];
	steps_emptying: PlanningShiftStepEmptying[];
	steps_roulage: PlanningShiftStepRoulage[];
	steps_waiting: PlanningShiftStepWaiting[];
	steps_administrative: PlanningShiftStepAdministrative[];
};

export type PlanningShiftStepCommon = {
	collect_id?: string;
	scheduled_at: ISODate;
	scheduled_end_at: ISODate;
	scheduled_service_time: string;
};
export type PlanningShiftStepRoulage = PlanningShiftStepCommon;
export type PlanningShiftStepWaiting = PlanningShiftStepCommon;
export type PlanningShiftStepService = PlanningShiftStepCommon & {
	category: PlanningShiftStepCategory.SERVICE;
	collect_config_id: string;
	customer_id: string;
} & ({ is_splitted: false } | { is_splitted: true; splitted_idx: number });
export type PlanningShiftStepAdministrative = PlanningShiftStepCommon & {
	category: PlanningShiftStepCategory.ADMINISTRATIVE;
	collect_config_id: string;
};
export type PlanningShiftStepEmptying = PlanningShiftStepCommon & {
	category: PlanningShiftStepCategory.EMPTYING;
	landfill_id: string;
	dumpster_to_take: DumpsterToTake | null;
};
export type DumpsterToTake = {
	nb_8m3: number;
	nb_15m3: number;
	nb_20m3: number;
	nb_30m3: number;
};
export type PlanningShiftStepManualEmptying = {
	id: string;
	scheduled_service_time: string;
	category: PlanningShiftStepCategory.EMPTYING;
	landfill_id: string;
	dumpster_to_take?: DumpsterToTake | null;
};

export type PlanningShiftStepDriver = PlanningShiftStepCommon & {
	category: PlanningShiftStepCategory.DRIVER_HOUSE_START | PlanningShiftStepCategory.DRIVER_HOUSE_END;
};
export type PlanningShiftStepBreak = PlanningShiftStepCommon & {
	category: PlanningShiftStepCategory.BREAK;
};

export type PlanningShiftStep = (
	| PlanningShiftStepService
	| PlanningShiftStepAdministrative
	| PlanningShiftStepEmptying
	| PlanningShiftStepDriver
	| PlanningShiftStepRoulage
	| PlanningShiftStepBreak
) & { category: PlanningShiftStepCategory };

export enum PlanningShiftStepCategory {
	SERVICE = 'SERVICE',
	EMPTYING = 'EMPTYING',
	ADMINISTRATIVE = 'ADMINISTRATIVE',
	DRIVER_HOUSE_START = 'DRIVER_HOUSE_START',
	DRIVER_HOUSE_END = 'DRIVER_HOUSE_END',
	BREAK = 'BREAK',
	LATE_CANCELATION = 'LATE_CANCELATION',
}

export enum EPlanningRegion {
	PARIS = 'PARIS',
	LYON = 'LYON',
	MARSEILLE = 'MARSEILLE',
}
export enum EPlanningType {
	BIG_BAG = 'BIG_BAG',
	DUMPSTER = 'DUMPSTER',
}

export type PlanningExportRo = {
	plannings: PlanningRo[];
	trucks: TruckRo[];
	collectors: CollectorRo[];
	ccsService: CCServiceRo[];
	ccsAdministrative: CCAdministrativeRo[];
	customers: CustomerRo[];
	landfills: LandfillRo[];
	unplannedCCsService: CCServiceRo[];
	unplannedCCsAdministrative: CCAdministrativeRo[];
};

export type PlanningGetInstanceRo = BaseType & {
	isDraft: boolean;
	vrpData: {
		config: {
			collector: CollectorRo;
			truck: TruckRo;
		}[];
		vrp_algorithm_id: string;
		instance: VRPInstance | null;
		response: VRPDumpsterResponse | VRPBBResponse | null;
	}[];
};

export const MAX_DIFF_BETWEEN_START_AND_END = 15; // minimum difference between a planning start and end time in hours

export type DataOnRedisForVrpPlanning = {
	planning_id: string;
	vrp_algorithm_id: string;
	instance: VRPInstance | null;
	response: VRPDumpsterResponse | VRPBBResponse | null;
};

export type PlanningVersionDescriptionRo = {
	admin_id?: string;
	type: EPlanningType;
	region: EPlanningRegion;
	day: string; // YYYY-MM-DD
	timestamp?: number;
	key: string;
};
